body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: auto; /* Ensure scrolling is enabled */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
  .fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.2s ease-out 0.5s, transform 0.8s ease-out 0.5s; 
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
  }
  