/* ProjectCard.css */
.project-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .project-card {
    align-items: center;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    width: 90%; /* Adjusted to be almost the full width */
    max-width: 1200px; /* Maximum width for larger screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center the text inside the project card */
  }
  
  .project-carousel .carousel .slide img {
    max-width: 50vw;
    max-height: 50vh;
    height:auto;
    width: 100%;
  }

  .project-carousel .carousel .slide iframe.carousel-video {
    width:85%;
    height:30vw;
  }
  