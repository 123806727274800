.timeline-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden; /* Prevent overflow */
}

.timeline-line {
  position: absolute;
  width: 6px;
  background-color: #00aaff;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;
}

.timeline-item.left {
  justify-content: flex-end;
}

.timeline-item.left .content {
  text-align: right;
  align-items: flex-end;
}

.timeline-item.left::before {
  left: calc(50% - 7px);
}

.timeline-item.right {
  justify-content: flex-start;
}

.timeline-item.right .content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item.right::before {
  right: calc(50% - 7px);
}

.timeline-item::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #00aaff;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  position: relative;
  width: 45%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Larger shadow */
  text-align: center;
}

.timeline-year {
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-event {
  margin-bottom: 10px;
}

.timeline-image,
.timeline-video {
  max-width: 100%;
  margin-bottom: 10px;
}

.timeline-morelink {
  margin-top: 10px;
}

.timeline-morelink a {
  text-decoration: none;
  color: #00aaff;
}

.timeline-morelink a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .timeline-container {
    width: 95%;
  }

  .timeline-line {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .timeline-item {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .timeline-item.left,
  .timeline-item.right {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }
  
  .timeline-item::before {
    display: none; /* Hide the dots on mobile */
  }
  
  .content {
    width: 90%;
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
}
