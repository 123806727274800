.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Larger shadow */
    width: 100%;
    max-width: 400px;
    height: auto; /* Adjust height to fit content */
    margin-bottom: 20px;
    text-align: center;
  }
  
  .card-image {
    width: 75%;
    max-width: 300px;
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Make the corners rounded */
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .card-content h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .card-content p {
    margin: 10px 0;
  }
  
  .card-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .card-links a {
    display: inline-block;
    margin: 5px 10px;
    text-decoration: none;
    color: #00aaff;
  }
  
  .card-links a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .card {
      padding: 15px;
      max-width: 90%;
    }
  
    .card-image {
      max-width: 100%;
    }
  
    .card-content h2 {
      font-size: 1.2em;
    }
  
    .card-content p {
      font-size: 0.9em;
      margin: 5px 0;
    }
  
    .card-links a {
      margin: 5px 5px;
      font-size: 0.9em;
    }
}

  